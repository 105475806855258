/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import { MenuItem, List, ListItem, Paper } from '@mui/material'

import {
  Cartesian3,
  Color,
  MapboxStyleImageryProvider,
  Viewer as CesiumView,
  Entity,
  SceneMode,
} from 'cesium'
import React from 'react'
import {
  Viewer,
  ImageryLayer,
  Camera,
  CameraFlyTo,
  Entity as ResiumEntity,
  CesiumComponentRef,
} from 'resium'
import { newProjectSelector, setPolygonPoints } from '../store/new_project'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { ProtectedComponent } from '../components/protected_route'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@cesium-extends/drawer'
import {
  calculateArea,
  calculatePerimeter,
  toDegrees,
} from '../utils/functions'
import { mapboxConfig } from '../utils/constants'
import Colors from '../utils/colors'
import SearchIcon from '../assets/svgs/search.svg'
import C3SpectraAssetDetLogo from '../assets/svgs/spectra_asset_logo.svg'
import WestIcon from '@mui/icons-material/West'
import { ReactComponent as DDIcon } from '../assets/svgs/dropdown_icon.svg'
import { ReactComponent as ScaleIcon } from '../assets/svgs/scale_icon.svg'
import { ReactComponent as DrawIcon } from '../assets/svgs/draw_icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/svgs/delete_icon.svg'
import ProjectStep from '../components/project_step'
import { errorToast, successToast } from '../utils/toast'
import {
  useGetProjectQuery,
  useUploadPolygonMutation,
} from '../services/project_services'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../utils/parsers'

const units = ['meter', 'feet']
const DrawInput: React.FC = () => {
  const query = useQuery()
  const projectId = query.get('project')
  const navigate = useNavigate()
  const viewer = React.useRef<CesiumView>()
  const drawerTool = React.useRef<Drawer>()
  const cesiumContainer = React.useRef<any>()
  const dispatch = useAppDispatch()
  const [isCesiumInit, setIsCesiumInit] = React.useState<boolean>(false)
  const [selectDistanceUnit, setDistanceUnit] = React.useState<boolean>(false)
  const [isParsing, setIsParsing] = React.useState<boolean>(false)
  const newProject = useAppSelector(newProjectSelector)
  const imgProvider = React.useMemo(
    () => new MapboxStyleImageryProvider(mapboxConfig),
    []
  )
  const [distance, setDistance] = React.useState<number>(10)
  const [unit, setUnit] = React.useState<string>('meter')
  const [upload, { isLoading }] = useUploadPolygonMutation()
  const [showBoundaryCalc, setShowBoundaryCalc] = React.useState<boolean>(false)
  const [currentEntity, setCurrentEntity] = React.useState<Entity | null>(null)
  // eslint-disable-next-line
  const { data: project, isLoading: projectLoading } = useGetProjectQuery(
    projectId?.toString() ?? ''
  )

  // To Prevent the React.StrictMode Effect on cesium
  React.useEffect(() => {
    if (!isCesiumInit) {
      setTimeout(() => {
        setIsCesiumInit(true)
      }, 1000)
    }
  }, [])

  React.useEffect(() => {
    if (projectId == null) {
      setTimeout(() => {
        navigate('/projects')
      }, 500)
    }
  }, [projectId])

  const initDrawer = (e: CesiumComponentRef<CesiumView> | null) => {
    if (e != null) {
      if (e.cesiumElement !== viewer.current && e.cesiumElement != null) {
        viewer.current = e.cesiumElement
        drawerTool.current = new Drawer(viewer.current!, {
          sameStyle: true,
          tips: {
            start: undefined,
            end: undefined,
            init: undefined,
          },
        })
      }
    }
  }

  const draw = () => {
    if (newProject.polygonPoints.length > 0) {
      dispatch(setPolygonPoints([]))
    }
    if (currentEntity != null) {
      viewer.current?.entities.remove(currentEntity)
    }
    drawerTool.current?.start({
      type: 'POLYGON',
      finalOptions: {
        material: Color.TRANSPARENT,
        outlineColor: Color.BLUE,
      },
      dynamicOptions: {
        material: Color.TRANSPARENT,
        outlineColor: Color.BLUE,
      },
      onEnd: (entity: Entity, positions: Cartesian3[]) => {
        dispatch(setPolygonPoints(positions))
        setCurrentEntity(entity)
        setShowBoundaryCalc(true)
      },
    })
  }
  const onNext = () => {
    if (newProject.polygonPoints.length === 0) {
      errorToast('Please draw the analysis area on the map')
      viewer.current?.entities?.removeAll()
    } else {
      const param = {
        project_id: parseInt(projectId ?? ''),
        distance_interval: distance,
        road_api: true,
        polygons_cords: `[[${newProject.polygonPoints.map((pos: any) => `(${toDegrees(pos)})`).join(',')}]]`,
      }
      upload(param)
      successToast('Polygon processing started')
      setTimeout(() => {
        navigate(`/run_analysis?project=${projectId}&from=draw_input`)
      }, 500)
    }
  }

  return (
    <Box display="flex" flexDirection="column" className="h-screen">
      <Box flex={15} display="flex" flexDirection="column" alignItems="stretch">
        <Box flex={1} />
        <Box
          flex={8}
          display="flex"
          flexDirection="row"
          px="18px"
          alignContent="space-around"
        >
          <Box
            flex={3}
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="start"
          >
            <img src={C3SpectraAssetDetLogo} alt="Spectra Discovery" />
          </Box>
          <Box
            flex={12}
            display="flex"
            flexDirection="column"
            alignItems="start"
          >
            <Typography
              sx={{
                flex: 2,
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '32.4px',
                color: 'white.main',
              }}
            >
              Create Project
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="end"
          >
            <IconButton
              sx={{
                borderRadius: '8px',
                backgroundColor: 'bg.200',
              }}
              onClick={() => {
                navigate(-1)
              }}
            >
              <CloseIcon sx={{ color: 'textColor.main', fontSize: '15px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box flex={2} />
      </Box>
      <Box
        display="flex"
        flex={74}
        flexDirection="row"
        alignItems="stretch"
        maxHeight="75vh"
      >
        <Box flex={2.5} />
        <Box
          flex={10.5}
          display="flex"
          flexDirection="row"
          justifyItems="space-between"
          mr="20px"
        >
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            bgcolor="bg.200"
            borderRadius="20px"
            p="20px"
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box flex={12} display="flex" flexDirection="column">
              <Box flex={1.2}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.6px',
                    textAlign: 'start',
                  }}
                >
                  Specify the Sampling Distance and use the Polygon tool to draw
                  the analysis area on the map.
                </Typography>
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection="row"
                alignItems="start"
              >
                <Box
                  flex={1.3}
                  display="flex"
                  flexDirection="column"
                  alignContent="stretch"
                  pr="7px"
                >
                  <TextField
                    placeholder="Sampling Distance"
                    variant="outlined"
                    size="small"
                    defaultValue={distance.toString()}
                    onChange={(e: { target: { value: string | number } }) => {
                      setDistance(+e.target.value)
                    }}
                  />
                </Box>
                <Box
                  flex={1.3}
                  display="flex"
                  flexDirection="column"
                  alignContent="stretch"
                >
                  <Box
                    border="2px solid"
                    borderColor="bg.300"
                    sx={{
                      width: '186px',
                      height: '40px',
                      borderRadius: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      px: '10px',
                    }}
                    onClick={() => setDistanceUnit(true)}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: 'textColor.300',
                        textAlign: 'center',
                      }}
                    >
                      {unit}
                    </Typography>
                    <DDIcon />
                    {selectDistanceUnit ? (
                      <Modal
                        open={selectDistanceUnit}
                        onClose={() => {
                          setDistanceUnit(false)
                        }}
                      >
                        <Box
                          height={100}
                          sx={{
                            width: 400,
                            borderRadius: '8px',
                            position: 'absolute',
                            top: '35%',
                            left: '40%',
                            border: 'none',
                            boxShadow: 24,
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'stretch',
                          }}
                        >
                          {units.map((u) => (
                            <Box
                              flex={1}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              bgcolor={u === unit ? 'bg.200' : 'bg.300'}
                              pl="10px"
                              onClick={() => {
                                setUnit(u)
                                setTimeout(() => {
                                  setDistanceUnit(false)
                                }, 100)
                              }}
                            >
                              <Typography
                                sx={{
                                  color: 'textColor.300',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {u}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Modal>
                    ) : null}
                  </Box>
                  {/*<FormControl error={false} size="small">
                    <InputLabel id="units-label">Units</InputLabel>
                    <Select
                      labelId="units-label"
                      id="units"
                      label="Units"
                      defaultValue=""
                      value={newProject.typeOfAsset}
                      onChange={(event) => {
                        // dispatch(setTypeOfAsset(event.target.value))
                      }}
                    >
                      <MenuItem value={'meter'}>Meters</MenuItem>
                      <MenuItem value={'feet'}>Feets</MenuItem>
                    </Select>
                  </FormControl>*/}
                </Box>
                <Box flex={1} />
                <Box
                  flex={2}
                  display="flex"
                  flexDirection="column"
                  alignContent="stretch"
                >
                  <CesiumSearchField viewerRef={viewer} />
                </Box>
              </Box>
            </Box>
            <Box flex={3} />
            <Box
              flex={77}
              display="flex"
              flexDirection="column"
              sx={{
                overflow: 'hidden',
                borderRadius: '20px',
              }}
            >
              <div
                ref={cesiumContainer}
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  borderRadius: '20px',
                  overflow: 'hidden',
                }}
              >
                {isCesiumInit ? (
                  <Viewer
                    ref={initDrawer}
                    fullscreenButton={false}
                    homeButton={false}
                    sceneModePicker={false}
                    projectionPicker={false}
                    baseLayerPicker={false}
                    navigationHelpButton={false}
                    infoBox={false}
                    selectionIndicator={false}
                    timeline={false}
                    animation={false}
                    geocoder={false}
                    sceneMode={SceneMode.SCENE2D}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <ImageryLayer imageryProvider={imgProvider} />
                    <Camera />
                    {newProject.polygonPoints.length <= 0 ? (
                      <CameraFlyTo
                        destination={Cartesian3.fromDegrees(
                          -97.9227778,
                          36.6566667,
                          6000000
                        )}
                        duration={0}
                      />
                    ) : null}
                    {newProject.polygonPoints.map((point: any, index: any) => (
                      <ResiumEntity
                        key={index}
                        position={point}
                        point={{
                          color: Color.BLUE,
                          pixelSize: 8,
                          outlineColor: Color.WHITE,
                          outlineWidth: 1,
                        }}
                      />
                    ))}
                  </Viewer>
                ) : (
                  <CircularProgress />
                )}
              </div>
              {cesiumContainer?.current != null ? (
                <Box
                  sx={{
                    position: 'absolute',
                    ...cesiumContainer.current?.getBoundingClientRect(),
                    display: 'flex',
                    flexDirection: 'column',
                    width:
                      cesiumContainer.current?.getBoundingClientRect().width,
                    pointerEvents: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="end"
                    pr="10px"
                    pt="10px"
                  >
                    <IconButton
                      sx={{
                        borderRadius: '8px',
                        backgroundColor: 'bg.200',
                        mr: '10px',
                        px: '10px',
                        pointerEvents: 'auto',
                      }}
                      onClick={draw}
                    >
                      <DrawIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        borderRadius: '8px',
                        backgroundColor: 'bg.200',
                        maxWidth: '40px',
                      }}
                    >
                      <ScaleIcon />
                    </IconButton>
                  </Box>
                  {showBoundaryCalc ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="end"
                      pr="10px"
                      pt="10px"
                    >
                      <BoundaryDetails
                        points={newProject.polygonPoints}
                        onHide={() => {
                          setShowBoundaryCalc(false)
                        }}
                        onClear={() => {
                          dispatch(setPolygonPoints([]))
                          drawerTool.current?.reset()
                          setShowBoundaryCalc(false)
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        flex={13}
        display="flex"
        flexDirection="column"
        alignContent="stretch"
      >
        <Box flex={2} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="stretch"
          flexGrow={1}
        >
          <Box
            flex={3}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              startIcon={<WestIcon sx={{ color: 'textColor.300' }} />}
              sx={{
                bgcolor: 'bg.300',
                textTransform: 'none',
                borderRadius: '8px',
                color: 'textColor.300',
                minWidth: '109px',
              }}
              onClick={() => {
                dispatch(setPolygonPoints([]))
                setTimeout(() => {
                  navigate(-1)
                }, 100)
              }}
            >
              Back
            </Button>
          </Box>
          <Box
            flex={9}
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
          >
            <ProjectStep step={2} />
          </Box>
          <Box
            flex={4}
            display="flex"
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
            pr="20px"
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                textTransform: 'none',
                borderRadius: '8px',
                minWidth: '109px',
              }}
              onClick={onNext}
            >
              Create Project
            </Button>
          </Box>
        </Box>
        <Box flex={1} />
      </Box>
      <Modal
        disableEscapeKeyDown
        open={isLoading || isParsing}
        onClose={() => {}}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={'h-screen'}
          bgcolor={'rgba(0, 0, 0, 0.5)'}
          sx={{ pointerEvents: 'none' }}
        >
          <CircularProgress />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '19.6px',
              color: 'white.200',
              mt: '10px',
            }}
          >
            {isParsing
              ? 'Collecting points....'
              : 'Generating points in selected area...'}
          </Typography>
        </Box>
      </Modal>
    </Box>
  )
}

interface BoundaryDetailsProps {
  points: Cartesian3[]
  onHide: () => void
  onClear: () => void
}
const BoundaryDetails: React.FC<BoundaryDetailsProps> = (
  props: BoundaryDetailsProps
) => {
  const perimeter = calculatePerimeter(props.points).toLocaleString(undefined, {
    maximumFractionDigits: 0,
  })
  const area = calculateArea(props.points).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      alignItems="stretch"
      bgcolor="bg.300"
      borderRadius="8px"
      p="20px"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignItems="center"
        justifyItems="center"
        mb="10px"
      >
        <Box width={'20px'} height={'20px'} mr={'8px'}>
          <DrawIcon />
        </Box>
        <Typography
          sx={{
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '24.3px',
            color: 'white.200',
          }}
        >
          Boundary
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="10px"
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'textColor.300',
          }}
        >
          Perimeter
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'white.200',
          }}
        >
          {perimeter ?? ''}m
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="10px"
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'textColor.300',
          }}
        >
          Area
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '19.6px',
            color: 'white.200',
            ml: '5px',
          }}
        >
          {area ?? ''}m2
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: 'primary.main',
            textTransform: 'none',
            borderRadius: '8px',
            minWidth: '90px',
            mr: '10px',
            pointerEvents: 'auto',
          }}
          onClick={() => {
            props.onHide()
          }}
        >
          Save
        </Button>
        <IconButton
          sx={{
            borderRadius: '8px',
            backgroundColor: 'bg.200',
            pointerEvents: 'auto',
          }}
          onClick={() => {
            props.onClear()
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
export const DrawInputView: React.FC = () => {
  return (
    <ProtectedComponent noSidebar>
      <DrawInput />
    </ProtectedComponent>
  )
}



// Search Component
const CesiumSearchField: React.FC<{ viewerRef: React.RefObject<any> }> = ({ viewerRef }) => {
  const [query, setQuery] = React.useState('')
  const [searchResults, setSearchResults] = React.useState<any[]>([])

// Fetch location results from OpenStreetMap's Nominatim API
const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value
  setQuery(value)

  if (value.length > 2) {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}&countrycodes=us&accept-language=en&limit=5`
      )
      const results = await response.json()
      setSearchResults(results)
    } catch (error) {
      console.error('Error fetching search results:', error)
    }
  } else {
    setSearchResults([])
  }
}


  // When a user selects a location, update the Cesium camera
  const handleSelectLocation = (location: any) => {
    setQuery(location.display_name)
    setSearchResults([])

    if (viewerRef.current) {
      const { lat, lon } = location
      viewerRef.current.camera.flyTo({
        destination: Cartesian3.fromDegrees(parseFloat(lon), parseFloat(lat), 10000), // Adjust altitude
      })
    }
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextField
        placeholder="Search location"
        variant="outlined"
        size="small"
        fullWidth
        value={query}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={SearchIcon} alt="search icon" />
            </InputAdornment>
          ),
        }}
      />
      {searchResults.length > 0 && (
        <Paper style={{ position: 'absolute', width: '100%', zIndex: 10 }}>
          <List>
            {searchResults.map((location: { display_name: any }, index: any) => (
              <ListItem
                key={index}
                button
                onClick={() => handleSelectLocation(location)}
              >
                {location.display_name}
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  )
}

export default CesiumSearchField

